<template>
    <div>
        <h3 class="pb-5 mb-5 text-xl font-bold">
            發起會員<span v-if="info.anonymous === 1" class="ml-3 text-red-500"
                >(資訊隱藏)</span
            >
        </h3>
        <div class="w-[50%] max-w-[400px] pr-5">
            <div class="flex items-center ml-5">
                <div class="w-[100px]">
                    <Avatar
                        :backgroundImg="info.user.avatar"
                        :size="['w-10', 'h-10']"
                    />
                </div>
                <p
                    class="flex-1 ml-5 text-red-500 underline"
                    @click="goProviderProfile(info.user.id)"
                >
                    {{ info.user.name }}
                </p>
                <div
                    class="ml-5 text-red-500 cursor-pointer"
                    @click="toMessage('members', info.user.banana_id)"
                >
                    <i class="fas fa-comment-alt"></i>
                </div>
            </div>
            <div class="ml-5">
                <div class="flex my-3">
                    <span class="text-gray-400 w-[100px]">手機</span>
                    <span class="flex-1 ml-5 text-bold">{{
                        info.user.phone
                    }}</span>
                    <span class="ml-5" @click="copy(info.user.phone)">
                        <i class="far fa-clone"></i>
                    </span>
                </div>
                <div class="flex my-3">
                    <span class="text-gray-400 w-[100px]">電子郵件</span>
                    <span class="flex-1 ml-5 text-bold">{{
                        info.user.email
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";
import Avatar from "@/components/Avatar.vue";
export default {
    name: "ActivityCreateUser",
    components: {
        Avatar,
    },
    props: {
        info: {
            type: Object,
            default() {
                return {
                    demand_id: "", //活動編號
                    name: "", //活動名稱
                    provider_required: 0, //需求人數
                    district: "", //城市
                    location: "", //地點
                    description: "", //描述
                    started_at: "",
                    ended_at: "",
                    created_at: "",
                    user: {
                        avatar: "",
                        name: "",
                    }, // 發起會員
                    due_at: "",
                    details: {},
                    enrollers: [],
                };
            },
        },
    },
    setup(props, { root }) {
        const { proxy } = getCurrentInstance();
        /**
         * 複製
         * @param { type String(字串) } text 手機號碼
         */
        const copy = (text) => {
            const dummy = document.createElement("input");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            proxy.$message({ type: "success", message: "複製成功" });
        };

        /**
         * @param { type String(字串) } tab 判斷預設選中聊天 tab 是服務商列表還是會員列表
         * @param { type String(字串) } id 會員 banana id
         */
        const toMessage = (tab, id) => {
            window.open(`/#/firestore/chats/${tab}/${id}`);
        };

        /**
         * @param { type String(字串) } id 會員 banana id
         */
        const goProviderProfile = (id) => {
            window.open(`/#/user_list/member_update/${id}`);
        };

        return {
            copy,
            toMessage,
            goProviderProfile,
        };
    },
};
</script>
