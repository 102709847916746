<template>
    <div>
        <h3 class="my-5 text-xl font-bold">活動資訊</h3>
        <div class="flex pt-3">
            <div class="flex-1 border-r border-gray-300">
                <ul class="pr-10">
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">活動編號</p>
                        <p>{{ info.demand_id }}</p>
                    </li>
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">發起來源</p>
                        <p>{{ info.source_application }}</p>
                    </li>
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">活動屬性</p>
                        <p>{{ info.is_x ? "CitybananaX" : "Citybanana" }}</p>
                    </li>
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">活動名稱</p>
                        <p>{{ info.name }}</p>
                    </li>
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">活動需求人數</p>
                        <p>{{ info.provider_required }}人</p>
                    </li>
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">活動地點</p>
                        <p>
                            {{
                                areas[info.district]
                                    ? areas[info.district].name
                                    : ""
                            }}
                            &nbsp;|&nbsp;{{ info.location }}
                        </p>
                    </li>
                    <li v-if="!$isEmpty(info.requirement)" class="flex mb-2">
                        <p class="flex-grow text-gray-400">其他需求備註</p>
                        <p class="flex-1 text-right">{{ info.requirement }}</p>
                    </li>
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">活動詳細內容</p>
                        <p class="flex-1 text-right">{{ info.description }}</p>
                    </li>
                </ul>
            </div>
            <div class="flex-1">
                <ul class="pl-10">
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">開始時間</p>
                        <p>{{ info.started_at | formatDateTime }}</p>
                    </li>
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">結束時間</p>
                        <p>{{ info.ended_at | formatDateTime }}</p>
                    </li>
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">活動成立時間</p>
                        <p>{{ info.created_at | formatDateTime }}</p>
                    </li>
                    <li class="flex mb-2">
                        <p class="flex-grow text-gray-400">招募截止時間</p>
                        <p>{{ info.due_at | formatDateTime }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { areas } from "@/langs/tw.json";

export default {
    name: "ActivityDetail",
    props: {
        info: {
            type: Object,
            default() {
                return {
                    demand_id: "", //活動編號
                    name: "", //活動名稱
                    provider_required: 0, //需求人數
                    district: "", //城市
                    location: "", //地點
                    description: "", //描述
                    started_at: "",
                    ended_at: "",
                    created_at: "",
                    user: {
                        avatar: "",
                        name: "",
                    }, // 發起會員
                    due_at: "",
                    details: {},
                    enrollers: [],
                };
            },
        },
    },
    data() {
        return {
            areas,
        };
    },
};
</script>

<style lang="scss" scoped>
li {
    @apply pb-2;
}
</style>
