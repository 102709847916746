<template>
    <!-- 收益 -->
    <div class="section-content pt-3">
        <div class="left">
            <div class="section-title">
                付款金額<span v-if="info.paid_by === 1" class="text-red-500"
                    >(現金付款)</span
                >
            </div>
            <div class="flex w-[80%]">
                <div class="line-item">
                    <div class="label">實付儲值金</div>
                    <div class="text">
                        $
                        {{
                            info.paid_by === 1
                                ? 0
                                : info.details.pointPaid | formatCurrency
                        }}
                    </div>
                </div>
                <div class="line-item">
                    <div class="label">實付現金</div>
                    <div class="text">
                        $
                        {{
                            info.paid_by === 0
                                ? 0
                                : info.details.pointPaid | formatCurrency
                        }}
                    </div>
                </div>
            </div>
            <div class="line-item">
                <div class="label">退還儲值金</div>
                <div class="text">
                    $
                    {{ Math.abs(info.details.pointRefunded) | formatCurrency }}
                </div>
            </div>
        </div>
        <div class="right">
            <div class="section-title">折抵金</div>
            <div class="line-item">
                <div class="label">實付折抵金</div>
                <div class="text">
                    $ {{ info.details.voucherPaid | formatCurrency }}
                </div>
            </div>
            <div class="line-item">
                <div class="label">退還折抵金</div>
                <div class="text">
                    $ {{ info.details.voucherRefunded | formatCurrency }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ActivityRemuneration",
    props: {
        info: {
            type: Object,
            default() {
                return {
                    demand_id: "", //活動編號
                    name: "", //活動名稱
                    provider_required: 0, //需求人數
                    district: "", //城市
                    location: "", //地點
                    description: "", //描述
                    started_at: "",
                    ended_at: "",
                    created_at: "",
                    user: {
                        avatar: "",
                        name: "",
                    }, // 發起會員
                    due_at: "",
                    details: {},
                    enrollers: [],
                };
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text-style.scss";
.section-content {
    margin-top: 20px;
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: solid 1px #cbcbcb;

    .section-title {
        width: 80%;
        margin-bottom: 20px;
        @extend .text-black-blod-18;
    }

    &:last-child {
        border-bottom: none;
    }

    .left {
        flex: 1;
        border-right: 1px solid #cbcbcb;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .right {
        flex: 1;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .line-item {
        display: flex;
        justify-content: flex-start;
        width: 80%;
        align-items: flex-start;
        margin-top: 10px;
        .label {
            color: #757575;
            font-size: 14px;
            font-weight: 500;
            margin-right: 10px;
            width: 100px;
        }
        .text {
            width: 200px;
            overflow-wrap: break-word;
        }
        .icon {
            cursor: pointer;
            margin-left: 20px;
        }
    }
}
</style>
