<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <h3 class="pb-5 mb-5 text-xl font-bold">
            活動報名狀況<span v-if="enrollers.length > 0" class="text-red-500"
                >({{ enrollers.length }}人)</span
            >
        </h3>
        <table class="w-full">
            <thead>
                <tr class="bg-gray-200">
                    <td class="py-3 pl-5">服務商</td>
                    <td class="pl-5">報價</td>
                    <td
                        v-if="
                            enrollers.length > 0 &&
                            enrollers.find((item) => item.travel_time > 0) !==
                                undefined
                        "
                        class="pl-5"
                    >
                        交通/抵達
                    </td>

                    <td class="pl-5">狀態</td>
                    <td class="pl-5">回覆</td>
                    <td v-if="info.hourly_pay === 0" class="pl-5">
                        訂單進帳總額
                    </td>
                    <td class="pl-5">聯繫</td>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="item in enrollers"
                    :key="item.id"
                    class="border-b border-gray-300"
                >
                    <td
                        class="py-5 pl-5 cursor-pointer"
                        @click="goProviderProfile(item.user.id)"
                    >
                        <div class="flex items-center">
                            <Avatar
                                :backgroundImg="item.user.avatar"
                                :size="['w-10', 'h-10']"
                            />
                            <p class="ml-5 text-red-500 underline">
                                {{ item.user.name }}
                            </p>
                        </div>
                    </td>
                    <td class="pl-5 font-bold OpenSansFont">
                        ${{ item.hourly_pay | formatCurrency }}
                    </td>
                    <td v-if="item.travel_time > 0" class="pl-5 font-medium">
                        <div v-if="item.status === 0">
                            {{ item.travel_time }}分鐘
                        </div>
                        <div v-else>
                            {{
                                $moment(item.started_at)
                                    .add(item.travel_time, "minutes")
                                    .format("MM/DD HH:mm")
                            }}
                        </div>
                        <!-- <pre>{{ item }}</pre> -->
                    </td>

                    <td class="pl-5 font-bold">
                        {{ item.status | flashProviderSignUpStatus }}
                        <span
                            v-if="item.dating_id"
                            @click="goOrderDetail(item.dating_id)"
                        >
                            (<span
                                class="text-red-500 underline cursor-pointer"
                            >
                                {{ item.dating.order_id }}</span
                            >
                            )
                        </span>
                    </td>

                    <td class="pl-5 font-bold">
                        <div v-if="item.status === 0">
                            <button
                                class="px-2 py-1 mr-2 text-sm text-white bg-red-500 rounded-lg myDisabled"
                                :disabled="
                                    memberData.wallet.balance <
                                        item.hourly_pay && info.paid_by !== 1
                                "
                                @click="updateEnrollerDating(item.id, 1)"
                            >
                                接受
                            </button>
                            <button
                                class="px-2 py-1 text-sm text-black border border-black rounded-lg"
                                @click="updateEnrollerDating(item.id, -1)"
                            >
                                婉拒
                            </button>
                        </div>
                        <div v-else>已回覆</div>
                    </td>
                    <td v-if="info.hourly_pay === 0" class="pl-5 font-bold">
                        <div>
                            $
                            {{
                                (info.details.duration * item.hourly_pay +
                                    info.details.fee)
                                    | formatCurrency
                            }}
                        </div>
                    </td>
                    <td>
                        <div
                            class="ml-5 text-red-500 cursor-pointer"
                            @click="toMessage('providers', item.user.banana_id)"
                        >
                            <i class="fas fa-comment-alt"></i>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="enrollers.length === 0" class="no-data">尚未有報名者</div>
    </div>
</template>

<script>
// import composition-api
import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";
// api
import orderFlashApi from "@/api/cms/orderFlashApi.js";
import Avatar from "@/components/Avatar.vue";
// components
import LoadingComponent from "@/components/Loading.vue";

export default {
    name: "ActivitySignUpUser",
    components: {
        Avatar,
        LoadingComponent,
    },
    props: {
        enrollers: {
            type: Array,
            default() {
                return [];
            },
        },
        info: {
            type: Object,
            default() {
                return {};
            },
        },
        memberData: {
            type: Object,
            default() {
                return {
                    wallet: {
                        balance: 0,
                    },
                };
            },
        },
    },
    // eslint-disable-next-line no-unused-vars
    setup(props, { emit }) {
        // loading state
        const loading = ref(false);
        /**
         * @param { type String(字串) } id 會員 banana id
         */
        const goProviderProfile = (id) => {
            window.open(`/#/user_list/provider_update/${id}`);
        };

        /**
         * @param { type String(字串) } tab 判斷預設選中聊天 tab 是服務商列表還是會員列表
         * @param { type String(字串) } id 會員 banana id
         */
        const toMessage = (tab, id) => {
            window.open(`/#/firestore/chats/${tab}/${id}`);
        };

        /**
         * @param { type Number } status 是否同意 [integer | in:-1,1]
         * @param { type String(字串) } id 會員 banana id
         */
        const updateEnrollerDating = (id, status) => {
            loading.value = true;
            orderFlashApi
                .UpdateEnrollerDating(id, { status })
                .then(() => {
                    console.log("更新成功");
                })
                .catch(() => {
                    console.log("更新失敗");
                })
                .finally(() => {
                    loading.value = false;
                    emit("updateData");
                });
        };

        /**
         * @param { type String(字串) } id 訂單 order_id
         */
        const goOrderDetail = (id) => {
            window.open(
                `/#/0/OrderManageList/provider_update/order_detail/${id}`
            );
        };

        return {
            loading,
            // methods
            goProviderProfile,
            toMessage,
            updateEnrollerDating,
            goOrderDetail,
        };
    },
};
</script>
<style>
.no-data {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
}
</style>
