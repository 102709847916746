import Avatar from "@/components/Avatar.vue";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
export default {
    // 服務商查看即刻快閃訂單記錄
    name: "ActivityProviderViewRecord",
    props: {
        datas: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    emits: [],
    setup(props) {
        const route = useRoute();

        /**
         * @param { type String(字串) } id 訂單 order_id
         */
        const goOrderDetail = (id) => {
            window.open(
                `/#/0/OrderManageList/provider_update/order_detail/${id}`
            );
        };
        return {
            goOrderDetail,
            route,
        };
    },
    render() {
        return (
            <div class="mb-10">
                <h3 class="pb-5 mb-5 text-xl font-bold">
                    服務商查看紀錄
                    <span v-if="enrollers.length > 0" class="text-red-500">
                        ({this.datas.length}人)
                    </span>
                </h3>

                <table class="w-full">
                    <thead>
                        <tr class="bg-gray-200">
                            <td class="py-3 pl-5">服務商名稱</td>
                            {/* <td class="pl-5">查看時間</td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {this.datas.map((data) => (
                            <tr key={data.id} class="border-b border-gray-300">
                                <td class="py-5 pl-5 cursor-pointer">
                                    <div
                                        class="flex items-center"
                                        onClick={() =>
                                            this.goOrderDetail(
                                                this.route.params.id
                                            )
                                        }
                                    >
                                        <Avatar
                                            backgroundImg={data.avatar}
                                            size={["w-10", "h-10"]}
                                        />
                                        <p class="ml-5 text-red-500 underline">
                                            {data.name}
                                        </p>
                                    </div>
                                </td>
                                {/* <td class="py-5 pl-5 cursor-pointer">
                                    {data.id}
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {this.datas.length === 0 && (
                    <div class="w-full h-[50px] leading-[50px] text-center mt-3">
                        尚未有報名者
                    </div>
                )}
            </div>
        );
    },
};
