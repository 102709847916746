// 客訴單問題類型
const complaintType = {
    1: "用戶帳號相關",
    2: "聊天室檢舉",
    3: "預訂相關",
    4: "會員儲值相關",
    5: "服務商款項相關",
    6: "平台相關",
    7: "行程突發事件",
    8: "快閃預訂活動相關",
    99: "其他分類",
};
// 客訴單狀態
const complaintStatus = {
    0: "等待處理",
    1: "處理中",
    2: "審核中",
    3: "處理完畢",
};
// 客訴單緊急程度
const complaintSeverity = {
    0: "低等",
    1: "中等",
    2: "高等",
};

export default {
    complaintType,
    complaintStatus,
    complaintSeverity,
};
