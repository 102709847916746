<template>
    <!-- 客訴單 -->
    <div
        v-if="userFeedbacks.length > 0"
        class="mt-4 mb-0 bg-white rounded-lg detail-info"
    >
        <div class="section-content">
            <div class="section-title" style="width: 90%">客訴單</div>
            <div class="section-table">
                <div class="table-tr">
                    <td class="flex-1">編號</td>
                    <td class="flex-1">狀態</td>
                    <td class="flex-1">問題分類</td>
                    <td class="flex-1">建立人</td>
                    <td class="flex-1">建立時間</td>
                </div>
                <div v-for="i in userFeedbacks" :key="i.id" class="table-tr">
                    <td class="flex-1">
                        <span class="org-link" @click="toComplaint(i.id)">{{
                            i.id
                        }}</span>
                        <span
                            class="ml-2 cursor-pointer icon"
                            @click="copy(i.id)"
                        >
                            <i class="far fa-clone"></i>
                        </span>
                    </td>
                    <td class="flex-1">
                        {{ i.status | complaintStatus }}
                    </td>
                    <td class="flex-1">{{ i.type | complaintType }}</td>
                    <td class="flex-1">
                        {{ i.administrator ? i.administrator.name : "" }}
                    </td>
                    <td class="flex-1">
                        {{ i.created_at | formatDateTime }}
                    </td>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";
import "@/global/filters/filterComplaint";

export default {
    name: "ActivityComplaintList",
    props: {
        userFeedbacks: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    setup(props, { root }) {
        const { proxy } = getCurrentInstance();
        /**
         * 複製
         * @param { type String(字串) } text 手機號碼
         */
        const copy = (text) => {
            const dummy = document.createElement("input");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            proxy.$message({ type: "success", message: "複製成功" });
        };

        /**
         * @param { type String(字串) } tab 判斷預設選中聊天 tab 是服務商列表還是會員列表
         * @param { type String(字串) } id 會員 banana id
         */
        const toMessage = (tab, id) => {
            window.open(`/#/firestore/chats/${tab}/${id}`);
        };

        const toComplaint = (id) => {
            window.open(`/#/customer_complaint/customer/${id}`);
        };

        return {
            copy,
            toMessage,
            toComplaint,
        };
    },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_text-style.scss";
.detail-info {
    padding: 30px 8%;
    .section-title {
        width: 80%;
        margin-bottom: 20px;
        @extend .text-black-blod-18;
    }
    .section-table {
        .table-tr {
            width: 100%;
            min-height: 40px;
            display: flex;
            .org-link {
                color: #ff5733;
                text-decoration: underline;
                font-size: 14px;
                cursor: pointer;
                &.no-line {
                    text-decoration: none;
                }
            }
            &:first-child {
                background: #d1d1d1;
            }
            &.total {
                border-top: 1px solid #cbcbcb;
            }
            td:not(:first-child) {
                @extend .text-black-regular-14;
                padding: 0 20px;
                flex: 1;
                line-height: 40px;
                text-align: left;
            }
            td:nth-child(1) {
                @extend .text-black-regular-14;
                padding: 0 20px;
                width: 40%;
                line-height: 40px;
                text-align: left;
            }
        }
    }
}
</style>
