<template>
    <!-- 活動明細 -->
    <div class="section-content">
        <h3 class="pb-5 mb-5 text-xl font-bold section-title">
            活動合計<span class="text-red-500">{{
                info.paid_by === 1 ? "(現金單)" : ""
            }}</span>
        </h3>
        <div class="section-table">
            <div class="table-tr">
                <td>項目</td>
                <td>出席鐘點費</td>
                <td>活動總{{ info.details.unit === "hour" ? "時數" : "天數" }}</td>
                <td>小計</td>
            </div>
            <!-- 出席活動 -->
            <div class="table-tr">
                <td>出席活動</td>
                <td>$ {{ info.hourly_pay | formatCurrency }}</td>
                <td>{{ info.details.duration }}</td>
                <td>$ {{ info.details.eachPrice | formatCurrency }}</td>
            </div>
            <div class="table-tr total">
                <td></td>
                <td></td>
                <td>合計</td>
                <td>$ {{ info.details.eachPrice | formatCurrency }}</td>
            </div>
            <div class="table-tr">
                <td></td>
                <td></td>
                <td>平台服務費</td>
                <td>$ {{ info.details.eachFee | formatCurrency }}</td>
            </div>

            <div class="table-tr total">
                <td></td>
                <td></td>
                <td>活動總額</td>
                <td>
                    <span class="org-link no-line"
                        >$
                        {{
                            (info.details.eachPrice + info.details.eachFee)
                                | formatCurrency
                        }}
                    </span>
                </td>
            </div>
            <div class="table-tr">
                <td></td>
                <td></td>
                <td>需求人數</td>
                <td>x {{ info.provider_required }}</td>
            </div>
            <div class="table-tr">
                <td></td>
                <td></td>
                <td>使用快閃折抵金</td>
                <td>-$ {{ info.details.voucherUsed | formatCurrency }}</td>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ActivityTotal",
    props: {
        info: {
            type: Object,
            default() {
                return {
                    demand_id: "", //活動編號
                    name: "", //活動名稱
                    provider_required: 0, //需求人數
                    district: "", //城市
                    location: "", //地點
                    description: "", //描述
                    started_at: "",
                    ended_at: "",
                    created_at: "",
                    user: {
                        avatar: "",
                        name: "",
                    }, // 發起會員
                    due_at: "",
                    details: {},
                    enrollers: [],
                };
            },
        },
    },
    setup() {},
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text-style.scss";

.section-content {
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: solid 1px #cbcbcb;
    &:last-child {
        border-bottom: none;
    }
    .section-table {
        width: 100%;
        .table-tr {
            width: 100%;
            min-height: 40px;
            display: flex;
            .org-link {
                color: #ff5733;
                text-decoration: underline;
                font-size: 14px;
                cursor: pointer;
                &.no-line {
                    text-decoration: none;
                }
            }
            &:first-child {
                background: #d1d1d1;
            }
            &.total {
                border-top: 1px solid #cbcbcb;
            }
            td:not(:first-child) {
                @extend .text-black-regular-14;
                padding: 0 20px;
                flex: 1;
                line-height: 40px;
                text-align: left;
            }
            td:nth-child(1) {
                @extend .text-black-regular-14;
                padding: 0 20px;
                width: 40%;
                line-height: 40px;
                text-align: left;
            }
        }
    }
}
</style>
