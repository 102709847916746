<template>
    <!-- 新增客訴單 -->
    <div class="detail-info bg-white rounded-lg mt-4">
        <div class="section-content">
            <div class="salary-info">
                <div class="section-title" style="width: 90%">新增客訴</div>
                <div class="inputs">
                    <div class="input">
                        <div class="label">問題分類 *</div>
                        <el-select v-model="inputs.type" size="small" class="w-full" placeholder="選擇問題分類" clearable>
                            <el-option v-for="(type, index) in problemOptions" :key="index" :label="type.label" :value="type.value">
                                {{ type.label }}
                            </el-option>
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">發起人 手機 *</div>
                        <el-input v-model="inputs.phone" placeholder="請輸入發起人 手機" size="small"> </el-input>
                        <div v-if="showPhoneError" class="error">手機格式錯誤</div>
                        <el-radio v-model="radio" label="1">會員手機</el-radio>
                        <!-- <el-radio v-model="radio" label="2">服務商手機</el-radio> -->
                    </div>
                    <div class="input">
                        <div class="label">緊急程度 *</div>
                        <el-select v-model="inputs.severity" size="small" class="w-full" placeholder="請選擇緊急程度" clearable>
                            <el-option v-for="(severity, index) in severityOptions" :key="index" :label="severity.label" :value="severity.value">
                                {{ severity.label }}
                            </el-option>
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">客訴對象 手機</div>
                        <el-input v-model="inputs.defendant_phone" placeholder="請輸入客訴對象 手機" size="small"> </el-input>
                        <div v-if="showPhoneError2" class="error">手機格式錯誤</div>
                    </div>
                    <div v-if="inputs.type == 8 || inputs.type == 7" class="input">
                        <div class="label">客訴單編號*</div>
                        <el-input v-model="inputs.demand_id" disabled size="small"></el-input>
                    </div>
                    <div class="textarea">
                        <div class="label">問題描述 *</div>
                        <el-input v-model="inputs.feedback" placeholder="請輸入問題內容" type="textarea"> </el-input>
                    </div>
                </div>
                <!-- 上傳圖片區塊 -->
                <el-upload
                    v-if="images.length < 5"
                    action="#"
                    :limit="10"
                    multiple
                    :auto-upload="false"
                    :on-change="changeFile"
                    accept=".jpg, .jpeg, .png"
                    list-type="none"
                    :file-list="fileList">
                    <button class="orange-btn-outline-800 text-xs w-[100px] h-[26px] flex-1 mb-[2px]">上傳截圖</button>
                    <div slot="file" class="flex"></div>
                </el-upload>
                <div class="images">
                    <div v-for="(i, idx) in images" :key="idx" class="image" :style="{ background: `url('${i.base64}') no-repeat center` }">
                        <div class="trash" @click="deleteIMG(idx)">
                            <i class="far fa-trash-alt"></i>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center mt-3 border-gray-100 pt-2">
                    <el-button class="black-btn-outline md:text-sm text-xs h-[40px] w-[150px]" @click="showCustomerComplaint = false">
                        取消
                    </el-button>
                    <el-button
                        class="orange-btn-800 md:text-sm text-xs h-[40px] w-[150px]"
                        :disabled="disabled"
                        :loading="loading"
                        @click="addComplaint">
                        新增
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import orderComplaintConfig from "@/config/orderComplaintConfig";
import { dataURLtoBlob } from "@/service/imageTransfer.js";
import moment from "moment";

const inputsDefault = {
    phone: "", // 發起人手機
    defendant_phone: "", // 客訴對象 手機
    type: "",
    severity: "",
    feedback: "",
    demand_id: "",
};

export default {
    name: "AddOrderComplaint",
    props: {
        value: {
            type: Boolean,
            require: true,
        },
        info: {
            type: Object,
            default() {
                return {
                    demand_id: "", //活動編號
                    name: "", //活動名稱
                    provider_required: 0, //需求人數
                    district: "", //城市
                    location: "", //地點
                    description: "", //描述
                    started_at: "",
                    ended_at: "",
                    created_at: "",
                    user: {
                        avatar: "",
                        name: "",
                    }, // 發起會員
                    due_at: "",
                    details: {},
                    enrollers: [],
                };
            },
        },
    },
    computed: {
        showCustomerComplaint: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
        // 問題 options
        problemOptions() {
            const status = orderComplaintConfig.complaintType;
            const result = [];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return [
                {
                    label: "快閃預訂活動相關",
                    value: "8",
                },
            ];
        },
        // 警急程度 options
        severityOptions() {
            const status = orderComplaintConfig.complaintSeverity;
            const result = [];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
    },
    data() {
        return {
            loading: false,
            // 客訴單相關
            inputs: {
                ...inputsDefault,
            },
            images: [],
            showPhoneError: false,
            showPhoneError2: false,
            fileList: [],
            disabled: true,
            radio: "",
        };
    },
    methods: {
        // 客訴單相關
        changeFile(file, fileList) {
            if (file.size / (1024 * 1024) > 5) {
                this.fileList = [];
                alert("檔案大小超出5mb");
                return;
            }
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(file.raw);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                const fileStamp = {
                    time: moment().format("HHMMSSSS"),
                    url: file.url,
                    file: file.raw,
                    base64: reader.result,
                };

                if (this.images.length < 5) {
                    this.fileList = fileList.slice(-3);
                    this.images.push(fileStamp);
                } else {
                    alert("檔案張數超出限制");
                    return;
                }
            };
        },
        addComplaint() {
            this.loading = true;
            const {
                phone, // 發起人手機
                defendant_phone, // 客訴對象 手機
                type,
                severity,
                feedback,
                demand_id,
            } = this.inputs;

            const result = new FormData();
            result.append("phone", phone);
            if (type == 8 || type == 7) {
                result.append("demand_id", demand_id);
            }

            if (defendant_phone) {
                result.append("defendant_phone", defendant_phone);
            }

            result.append("type", type);
            result.append("severity", severity);
            result.append("feedback", feedback);

            this.images.forEach((i) => {
                if (i.base64) {
                    const blob = dataURLtoBlob(i.base64);
                    result.append("images[]", blob);
                }
            });

            this.$api
                .CreateUserFeedback(result)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "新增成功",
                    });
                    this.showCustomerComplaint = false;
                    this.clearComplaintInputs();
                    this.$emit("updateData");
                })
                .catch((err) => {
                    const errMessage = err.response?.data.error.message ? err.response?.data.error.message : "新增失敗";

                    this.$message({
                        type: "error",
                        message: errMessage,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearComplaintInputs() {
            this.inputs = { ...inputsDefault };
            this.inputs.demand_id = this.info.demand_id;
            this.inputs.type = "3";
            this.images = [];
            this.fileList = [];
        },
        deleteIMG(idx) {
            this.images.splice(idx, 1);
        },
    },
    watch: {
        inputs: {
            deep: true,
            immediate: true,
            handler(val) {
                const {
                    phone, // 發起人手機
                    type,
                    severity,
                    feedback,
                    demand_id,
                    defendant_phone,
                } = val;
                const phoneMatch = /886\d{7,17}$/.test(phone);
                const defendantPhoneMatch = defendant_phone ? /886\d{7,17}$/.test(defendant_phone) : true;

                if (type == 7 || type == 8) {
                    if (phoneMatch && type && severity && feedback && demand_id && defendantPhoneMatch) {
                        this.disabled = false;
                        this.showPhoneError = false;
                        this.showPhoneError2 = false;
                    } else {
                        this.disabled = true;
                    }
                } else {
                    if (phoneMatch && type && severity && feedback) {
                        this.showPhoneError = false;
                        this.disabled = false;
                    } else {
                        this.disabled = true;
                    }
                }
            },
        },
        radio(val) {
            if (val == 1) {
                this.inputs.phone = this.info.user.phone;
            }
            this.showPhoneError = false;
        },
    },
    mounted() {
        this.inputs.demand_id = this.info.demand_id;
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text-style.scss";

.error {
    font-size: 12px;
    color: red;
    margin-top: 2px;
}

.section-title {
    width: 80%;
    margin-bottom: 20px;
    @extend .text-black-blod-18;
}

.inputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    .input {
        width: 48%;
        margin: 8px 0;
    }
    .textarea {
        width: 100%;
        margin: 8px 0;
    }
    .label {
        font-size: 14px;
        color: #757575;
        margin-bottom: 5px;
    }
}

.images {
    width: 100%;
    max-width: 100%;
    overflow: scroll;
    display: flex;
    align-items: center;
    .image {
        display: flex;
        width: 25%;
        min-width: 25%;
        height: 200px;
        margin: 10px 5px;
        background-size: contain !important;
        &:hover {
            cursor: pointer;
            .trash {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                background: rgba(0, 0, 0, 0.2);
                font-size: 20px;
            }
        }
        .trash {
            display: none;
        }
    }
}
</style>
