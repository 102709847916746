<template>
    <div>
        <!-- 取消原因 -->
        <div v-if="info.details.closedNote" class="section-content">
            <div class="section-title" style="width: 90%">報名截止原因</div>
            <div class="line-item2">{{ info.details.closedNote }}</div>
        </div>
        <div v-if="info.details.terminatedNote" class="section-content pt-3">
            <div class="section-title" style="width: 90%">活動終止原因</div>
            <div class="line-item2">{{ info.details.terminatedNote }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChangeStatusCourse",
    props: {
        info: {
            type: Object,
            default() {
                return {
                    demand_id: "", //活動編號
                    name: "", //活動名稱
                    provider_required: 0, //需求人數
                    district: "", //城市
                    location: "", //地點
                    description: "", //描述
                    started_at: "",
                    ended_at: "",
                    created_at: "",
                    user: {
                        avatar: "",
                        name: "",
                    }, // 發起會員
                    due_at: "",
                    details: {},
                    enrollers: [],
                };
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text-style.scss";
.section-title {
    width: 80%;
    margin-bottom: 20px;
    @extend .text-black-blod-18;
}
.section-content {
    margin-top: 20px;
    overflow: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid rgb(133, 130, 130);

    .left {
        flex: 1;
        border-right: 1px solid #cbcbcb;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .right {
        flex: 1;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .line-item2 {
        display: flex;
        justify-content: flex-start;
        width: 90%;
        align-items: flex-start;
    }
}
</style>
