<template>
    <div>
        <!-- collapse  -->
        <el-collapse>
            <el-collapse-item>
                <template slot="title">
                    <div class="items-center px-4 collapse-title">
                        <div class="title">
                            活動狀態 : {{ info.status | flashOrderStatus }}
                            <i class="ml-2 fas fa-chevron-down"></i>
                        </div>
                        <button
                            v-permission="['create']"
                            class="orange-btn-800 md:text-sm text-xs h-[30px]"
                            @click.stop="showCustomerComplaint = true"
                        >
                            建立客訴
                        </button>
                    </div>
                </template>
                <div
                    v-for="log in logs.slice().reverse()"
                    :key="log.created_at"
                    class="collapse-item"
                >
                    <span>{{ log.status | flashOrderStatus }} ：</span>
                    {{ log.created_at | formatDateTime }} ｜
                    <span v-if="log.administrator">{{
                        log.administrator.name
                    }}</span>
                    <span v-else-if="log.user">{{ log.user.name }}</span>
                </div>
            </el-collapse-item>
        </el-collapse>
        <!-- 新增客訴單 -->
        <AddOrderComplaint
            v-if="showCustomerComplaint"
            v-model="showCustomerComplaint"
            :info="info"
            @updateData="getData(id)"
        />
        <!-- 客訴單列表 -->
        <ActivityComplaintList :userFeedbacks="userFeedbacks" />
        <div class="bg-white rounded-lg p-10 px-[8%] mt-3">
            <!-- 活動資訊 -->
            <Detail :info="info" />
            <div class="border-line"></div>
            <!-- 發起會員 -->
            <CreateUser :info="info" />
            <div class="border-line"></div>
            <!-- 活動查看紀錄 -->
            <ActivityProviderViewRecord :datas="viewProviders" />
            <!-- 活動報名狀況 -->
            <SignUpUser
                :enrollers="enrollers"
                :info="info"
                :memberData="memberData"
                @updateData="getData(id)"
            />
            <div class="border-line"></div>
            <!-- 活動合計 -->
            <ActivityTotal v-if="info.hourly_pay > 0" :info="info" />
            <div v-if="info.hourly_pay > 0" class="border-line"></div>
            <!-- 變更原因 -->
            <ChangeStatusCourse :info="info" />
            <!-- 收益 -->
            <ActivityRemuneration :info="info" />
            <div class="actions">
                <button
                    class="mr-5 black-btn-outline-lg text-md"
                    @click="backList"
                >
                    返回活動列表
                </button>
                <button
                    v-if="info.status >= 0"
                    class="orange-btn-800-lg text-md"
                    @click="showDialog = true"
                >
                    變更活動狀態
                </button>
            </div>
        </div>
        <PopUpUpdateFlashOrder
            :id="id"
            v-model="showDialog"
            :info="info"
            :status="info.status"
            :userFeedbackOptions="userFeedbackOptions"
            @update="getData"
        />
    </div>
</template>

<script>
// import composition-api
import {
    ref,
    reactive,
    del,
    set,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// api
import orderFlashApi from "@/api/cms/orderFlashApi.js";
// components
import Detail from "./components/ActivityDetail.vue";
import CreateUser from "./components/ActivityCreateUser.vue";
import SignUpUser from "./components/ActivitySignUpUser.vue";
import ActivityTotal from "./components/ActivityTotal.vue";
import ActivityRemuneration from "./components/ActivityRemuneration.vue";
import ActivityComplaintList from "./components/ActivityComplaintList.vue";
import AddOrderComplaint from "./components/AddOrderComplaint.vue";
import PopUpUpdateFlashOrder from "../../components/PopUpUpdateFlashOrder.vue";
import ActivityProviderViewRecord from "./components/ActivityProviderViewRecord";
import ChangeStatusCourse from "./components/ChangeStatusCourse.vue";

export default {
    name: "rightnnowActivityDetail",
    components: {
        Detail,
        CreateUser,
        SignUpUser,
        ActivityTotal,
        ActivityRemuneration,
        AddOrderComplaint,
        ActivityComplaintList,
        ChangeStatusCourse,
        PopUpUpdateFlashOrder,
        ActivityProviderViewRecord,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const { proxy } = getCurrentInstance();

        // 活動資訊相關
        const id = computed(() => route.params.id);
        const info = ref({
            demand_id: "", //活動編號
            name: "", //活動名稱
            provider_required: 0, //需求人數
            district: "", //城市
            location: "", //地點
            description: "", //描述
            started_at: "",
            ended_at: "",
            created_at: "",
            // 發起會員
            user: {
                avatar: "",
                name: "",
            },
            due_at: "",
            details: {},
            user_feedbacks: [],
        });

        const memberData = ref({
            wallet: {
                balance: 0,
            },
        });

        // 即刻快閃服務雙查看紀錄
        const viewProviders = ref([]);
        const getData = async () => {
            // 取得資料
            orderFlashApi.SearchDemandsDatingSingleApi(id.value).then((res) => {
                info.value = { ...res.data };
                enrollers.value = [...res.data.enrollers];
                getUserData(info.value.user.id);
            });
            orderFlashApi.GetFlashOrderLogs(id.value).then((res) => {
                logs.value = [...res.data.logs];
            });
        };

        /**
         * 取得使用者資料
         */
        async function getUserData(userId) {
            try {
                // 取得使用者資料
                const { data } = await proxy.$api.GetUserDataApi(userId);
                memberData.value = data;
            } catch (err) {
                console.log(err);
                proxy.$message({
                    type: "error",
                    message: "取得使用者資料失敗",
                });
            }
        }

        // 報名者相關
        const enrollers = ref([]);

        // 變更狀態相關
        const logs = ref([]);
        const showDialog = ref(false);

        // 客訴單相關
        const showCustomerComplaint = ref(false);
        const userFeedbacks = computed(() => info.value.user_feedbacks);
        const userFeedbackOptions = computed(() => {
            const result = [];
            userFeedbacks.value.forEach((i) => {
                result.push({
                    label: i.id,
                    value: i.id,
                });
            });
            return result;
        });

        // 返回列表
        const backList = () => {
            router.push({ name: "rightnow_activity_list" });
        };

        /**
         * 取得服務商查看即刻快閃紀錄
         * @param { type String(字串) } id 訂單 id
         */
        async function getFlashOrderProviderViewRecord(id) {
            try {
                const { data } =
                    await proxy.$api.GetFlashOrderProviderViewRecordApi(id);
                viewProviders.value = data.viewers;
                console.log("GetFlashOrderProviderViewRecordApi => ", data);
            } catch (err) {}
        }

        // lifecycles
        onActivated(() => {
            getData();
            getFlashOrderProviderViewRecord(id.value);
        });

        onMounted(() => {
            getData();
            getFlashOrderProviderViewRecord(id.value);
        });

        return {
            // data
            route,
            id,
            info,
            enrollers,
            showCustomerComplaint,
            showDialog,
            logs,
            userFeedbackOptions,
            userFeedbacks,
            // methods
            backList,
            getData,
            memberData,
            viewProviders,
        };
    },
};
</script>

<style scoped lang="scss">
@import "@/scss/_text-style.scss";

.collapse-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.action-bar {
    background: white;
    color: chocolate;
    margin: 10px 0;
    height: 55px;
}

::v-deep .el-collapse-item__arrow {
    display: none;
}

.collapse-item {
    span {
        color: #757575;
    }
}

::v-deep .el-collapse-item__content {
    padding-bottom: 10px;
    padding: 0 20px 10px 20px;
}

.detail-info {
    padding: 30px 8%;
    .section-title {
        width: 80%;
        margin-bottom: 20px;
        @extend .text-black-blod-18;
    }
    .section-table {
        .table-tr {
            width: 100%;
            min-height: 40px;
            display: flex;
            .org-link {
                color: #ff5733;
                text-decoration: underline;
                font-size: 14px;
                cursor: pointer;
                &.no-line {
                    text-decoration: none;
                }
            }
            &:first-child {
                background: #d1d1d1;
            }
            &.total {
                border-top: 1px solid #cbcbcb;
            }
            td:not(:first-child) {
                @extend .text-black-regular-14;
                padding: 0 20px;
                flex: 1;
                line-height: 40px;
                text-align: left;
            }
            td:nth-child(1) {
                @extend .text-black-regular-14;
                padding: 0 20px;
                width: 40%;
                line-height: 40px;
                text-align: left;
            }
        }
    }
}

.border-line {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(133, 130, 130);
    margin: 40px 0;
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
}
</style>
