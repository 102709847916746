/* eslint-disable no-unreachable */
<template>
    <!-- 彈窗 -->
    <Dialog
        :showDialog="showDialog"
        :customClass="'position'"
        :clickOutsideClose="false"
        @onCloseDialog="showDialog = false"
    >
        <div class="px-5 py-3 bg-white rounded pop-width md:p-10">
            <div class="close" @click="showDialog = false">
                <i class="fas fa-times"></i>
            </div>
            <div class="content">
                <div class="title">變更訂單狀態</div>
                <div class="status">
                    目前訂單狀態：
                    <span class="text-orange">
                        {{ status | flashOrderStatus }}
                    </span>
                </div>
            </div>

            <div class="details">
                <div class="detail">
                    <div class="label">合計</div>
                    <div class="value">
                        $ {{ info.details.eachPrice | formatCurrency }}
                    </div>
                </div>
                <div class="detail">
                    <div class="label">平台服務費</div>
                    <div class="value">
                        $ {{ info.details.eachFee | formatCurrency }}
                    </div>
                </div>
                <div class="detail">
                    <div class="label">需求人數</div>
                    <div class="value">x {{ info.provider_required }}</div>
                </div>
                <div class="detail">
                    <div class="label">訂單總額</div>
                    <div class="value">
                        $ {{ info.details.total | formatCurrency }}
                    </div>
                </div>
                <div class="detail">
                    <div class="label">快閃折抵金</div>
                    <div class="value">
                        $ {{ info.details.voucherUsed | formatCurrency }}
                    </div>
                </div>
                <div class="detail">
                    <div class="label">會員實付儲值金</div>
                    <div class="value text-orange">
                        $ {{ info.details.pointPaid }}
                    </div>
                </div>
            </div>

            <div class="inputs">
                <div class="input">
                    <div class="label">變更狀態</div>
                    <el-select
                        v-model="inputs.status"
                        size="small"
                        class="w-full"
                    >
                        <el-option
                            v-for="(status, index) in payStatusOptions"
                            :key="index"
                            :label="status.label"
                            :value="status.value"
                        >
                            {{ status.label }}
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="inputs">
                <div class="input">
                    <div class="label">客訴單編號</div>
                    <el-select
                        v-model="inputs.feedback_id"
                        size="small"
                        class="w-full"
                        placeholder="請選擇客訴單編號"
                    >
                        <el-option
                            v-for="(status, index) in userFeedbackOptions"
                            :key="index"
                            :label="status.label"
                            :value="status.value"
                        >
                            {{ status.label }}
                        </el-option>
                    </el-select>
                    <!-- <el-input v-model="inputs.feedback_id" size="small"> </el-input> -->
                </div>
            </div>
            <div class="inputs">
                <div class="input">
                    <div class="label">變更原因</div>
                    <el-input
                        v-model="inputs.note"
                        size="small"
                        type="textarea"
                    >
                    </el-input>
                </div>
            </div>
            <div class="err-msg">{{ errorMessage }}</div>
            <div class="flex justify-center pt-2 mt-3 border-gray-100">
                <el-button
                    class="orange-btn-800 md:text-sm text-xs h-[30px]"
                    :disabled="confirmDisabled"
                    :loading="loading"
                    @click="confirm"
                >
                    確認變更
                </el-button>
            </div>
        </div>
    </Dialog>
</template>

<script>
// import composition-api
import {
    ref,
    reactive,
    computed,
    onActivated,
    onMounted,
    watch,
    getCurrentInstance,
    defineExpose,
    onBeforeUnmount,
    nextTick,
} from "@vue/composition-api";
import Dialog from "@/components/Dialog.vue";
// import api
import orderFlashApi from "@/api/cms/orderFlashApi.js";

const defaultInputs = {
    status: null,
    feedback_id: "",
    note: "",
};

export default {
    name: "PopUpUpdateFlashOrder",
    components: { Dialog },
    props: {
        id: {
            type: [Number, String],
            require: true,
        },
        value: {
            type: Boolean,
            require: true,
        },
        // 訂單狀態
        status: {
            type: Number,
            require: true,
        },
        // 款項資訊
        info: {
            type: Object,
            default() {
                return {
                    demand_id: "", //活動編號
                    name: "", //活動名稱
                    provider_required: 0, //需求人數
                    district: "", //城市
                    location: "", //地點
                    description: "", //描述
                    started_at: "",
                    ended_at: "",
                    created_at: "",
                    user: {
                        avatar: "",
                        name: "",
                    }, // 發起會員
                    due_at: "",
                    details: {},
                    enrollers: [],
                };
            },
        },
        // 客訴單選項
        userFeedbackOptions: {
            type: Array,
            require: true,
            default: () => [],
        },
    },
    setup(props, { emit, root }) {
        const { proxy } = getCurrentInstance();
        // data
        const inputs = ref({
            ...defaultInputs,
        });
        const errorMessage = ref("");
        const confirmDisabled = ref(true);
        const loading = ref(false);

        // computed
        const payStatusOptions = computed(() => [
            {
                label: "報名截止",
                value: 2,
            },
            {
                label: "活動終止",
                value: -2,
            },
        ]);
        const showDialog = computed({
            get: () => props.value,
            set: (val) => {
                emit("input", val);
            },
        });

        // methods
        const confirm = () => {
            const request = { ...inputs.value };

            if (!request.feedback_id) {
                delete request.feedback_id;
            }

            if (!request.note) {
                delete request.note;
            }
            update(request);
        };

        const update = (request) => {
            errorMessage.value = "";
            loading.value = true;
            orderFlashApi
                .UpdateFlashDating(props.id, request)
                .then(() => {
                    showDialog.value = false;
                    proxy.$message({
                        type: "success",
                        message: "更新成功",
                    });
                    emit("update");
                })
                .catch((err) => {
                    if (err.response.data.error.error === 5028) {
                        errorMessage.value = "活動已開始，無法截止或終止活動";
                    }
                    proxy.$message({
                        type: "error",
                        message: "更新失敗",
                    });
                })
                .finally(() => {
                    loading.value = false;
                    emit("update");
                });
        };

        // watchs
        watch(
            inputs,
            (val) => {
                confirmDisabled.value = true;
                errorMessage.value = "";
                const { status, note, feedback_id } = val;
                // 必填欄位檢查
                confirmDisabled.value = !(status && note && feedback_id);
            },
            { deep: true }
        );

        watch(showDialog, () => {
            // 恢復預設
            inputs.value = {
                ...defaultInputs,
            };
        });

        return {
            // data
            inputs,
            errorMessage,
            confirmDisabled,
            loading,
            // computed
            payStatusOptions,
            showDialog,
            // methods
            confirm,
            update,
        };
    },
};
</script>

<style lang="scss" scoped>
.pop-width {
    width: 800px;
    margin: 0;
}
.content {
    .status {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        color: #707070;
    }
}

.details {
    border-radius: 10px;
    border: 1px solid #d1d1d1;
    margin: 10px;
    padding: 5px;
    .detail {
        display: flex;
        justify-content: space-between;
        padding: 5px 15px;
        .label {
            font-weight: 500;
            font-size: 14px;
            color: #707070;
        }
        .value {
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.inputs {
    .input {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        .label {
            width: 150px;
            font-size: 14px;
            text-align: center;
        }
    }
}

.text-orange {
    color: #ff5733;
    font-size: 14px;
}

.err-msg {
    width: 100%;
    text-align: center;
    color: red;
    font-size: 14px;
}
</style>
